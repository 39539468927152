.App {
  text-align: center;
}

td {
  text-align: center;
}


.radio label {
  display: flex;
  cursor: pointer;
  font-weight: 350;
  position: relative;
  overflow: hidden;
  margin-bottom: 0.375em;
}

.radio label input {
  position: absolute;
  left: -9999px;
}

.radio label input:checked+.radio-span {
  background-color: #d6dff7;
}

.radio label input:checked+.radio-span::before {
  box-shadow: inset 0 0 0 0.4375em #003acc;
}

.radio label .radio-span {
  display: flex;
  align-items: center;
  padding: 0.375em 0.75em 0.375em 0.375em;
  border-radius: 99em;
  transition: 0.25s ease;
}

.radio label .radio-span:hover {
  background-color: #d6dff7;
}

.radio label .radio-span::before {
  display: flex;
  flex-shrink: 0;
  content: "";
  background-color: #fff;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  margin-right: 0.375em;
  transition: 0.25s ease;
  box-shadow: inset 0 0 0 0.125em #003acc;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #000;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

@media (min-width: 1024px) {

  /* Show the table header rows and set all cells to display: table-cell */
  .my-custom-breakpoint td,
  .my-custom-breakpoint th,
  .my-custom-breakpoint tbody th,
  .my-custom-breakpoint tbody td,
  .my-custom-breakpoint thead td,
  .my-custom-breakpoint thead th {
    display: table-cell;
    margin: 0;
  }

  /* Hide the labels in each cell */
  .my-custom-breakpoint td .ui-table-cell-label,
  .my-custom-breakpoint th .ui-table-cell-label {
    display: none;
  }
}

.my-custom-breakpoint th {
  display: inline;
}


.hide-on-slim-screens {
  display: none;
}

@media screen and (min-width: 560px) {
  .hide-on-slim-screens {
    display: table-row;
  }
}